import React, { useEffect, useState } from 'react'
import BEMHelper from 'react-bem-helper'
import { localizedUrl } from '@src/utils/navigate'
import Link from '@src/components/Link'
import useTranslations from '@src/hooks/useTranslations'
import { SITE_TYPES } from '@src/bundles/site'

const bem = new BEMHelper('header')

export const TypeLinks = ({
	doSetSiteType,
	bemClass,
	siteType,
	language,
	service,
}) => {
	const t = useTranslations()
	const [siteTypeState, setSiteTypeState] = useState(SITE_TYPES.PRIVATE)
	
	useEffect(() => {
		setSiteTypeState(siteType)
	}, [siteType])

	return (
		<>
			<Link
				to={localizedUrl('/', language, service)}
				onClick={() => {
					doSetSiteType(SITE_TYPES.PRIVATE)
				}}
				{...(siteTypeState === SITE_TYPES.PRIVATE
					? { ...bem('link', bemClass, 'active') }
					: { ...bem('link', bemClass) })}
			>
				{t('kinto.header.private')}
			</Link>
			<Link
				to={localizedUrl('/foretag', language, service)}
				onClick={() => {
					doSetSiteType(SITE_TYPES.BUSINESS)
				}}
				{...(siteTypeState === SITE_TYPES.BUSINESS
					? { ...bem('link', bemClass, 'active') }
					: { ...bem('link', bemClass) })}
			>
				{t('kinto.header.business')}
			</Link>
		</>
	)
}
