import { getLanguageFromUrl, getServiceFromUrl, getSiteTypeFromUrl } from '@src/utils/navigate';

export default {
	name: 'site',
	getReducer: () => {
		let market = process.env.GATSBY_MARKET;
		let urlPathPrefix = process.env.GATSBY_PATH_PREFIX;
		let defaultLanguage = process.env.GATSBY_DEFAULT_LANGUAGE;
		const langArrayString = process.env.GATSBY_LANGUAGES;
		const langArray = langArrayString ? langArrayString.split(',') : [];
		const langFromUrl = getLanguageFromUrl(langArray);
		const serviceFromUrl = getServiceFromUrl(langArray);
		const siteType = getSiteTypeFromUrl(urlPathPrefix, langArray);

		const initialState = {
			type: siteType,
			bookingType: siteType,
			onboardingType: ONBOARDING_TYPES.PRIVATE,
			language: langFromUrl || defaultLanguage || 'sv',
			service: serviceFromUrl || SITE_TYPES.SHARE,
			market: market,
			error: undefined,
		};
		return (state = initialState, { type, payload }) => {
			switch (type) {
				case 'SET_TYPE':
					return { ...state, type: payload };
				case 'SET_BOOKING_TYPE':
					return { ...state, bookingType: payload };
				case 'SET_ONBOARDING_TYPE':
					return { ...state, onboardingType: payload };
				case 'SET_LANGUAGE':
					return { ...state, language: payload };
				case 'SET_SERVICE':
					return { ...state, service: payload, bookingType: SITE_TYPES.PRIVATE };
				case 'SET_MARKET':
					return {
						...state,
						market: payload,
						service: SITE_TYPES.SHARE,
						bookingType: SITE_TYPES.PRIVATE,
					};
				case 'SET_ERROR': {
					return { ...state, error: payload };
				}
				default:
					return state;
			}
		};
	},
	doSetSiteType:
		type =>
		({ dispatch }) => {
			dispatch({ type: 'SET_TYPE', payload: type });
		},
	selectSiteType: state => state.site.type,
	doSetBookingSiteType:
		type =>
		({ dispatch }) => {
			dispatch({ type: 'SET_BOOKING_TYPE', payload: type });
		},
	selectBookingSiteType: state => state.site.bookingType,
	doSetOnboardingSiteType:
		type =>
		({ dispatch }) => {
			dispatch({ type: 'SET_ONBOARDING_TYPE', payload: type });
		},
	selectOnboardingType: state => state.site.onboardingType,
	doSetLanguage:
		payload =>
		({ dispatch }) => {
			dispatch({ type: 'SET_LANGUAGE', payload });
		},
	selectLanguage: state => state.site.language,
	doSetService:
		payload =>
		({ dispatch }) => {
			dispatch({ type: 'RESET_SELECTED_STATION', payload: null });
			dispatch({ type: 'SET_SERVICE', payload });
		},
	selectService: state => state.site.service,
	doSetMarket:
		payload =>
		({ dispatch }) => {
			dispatch({ type: 'SET_MARKET', payload });
		},
	selectMarket: state => state.site.market,
	selectError: state => state.site.error,
	doSetError:
		payload =>
		({ dispatch }) => {
			dispatch({ type: 'SET_ERROR', payload });
		},
};

export const SITE_TYPES = {
	PRIVATE: 'private',
	BUSINESS: 'business',
	SHARE: 'share',
	FLEX: 'flex',
	JOIN: 'join',
};

export const ONBOARDING_TYPES = {
	PRIVATE: 'private',
	BUSINESS: 'business',
};
