import Client from '@src/custom-api/abstract-rest-client';
import { loadToken, setTokenValue, setTokenValueInStorage } from '@src/utils/tokenUtils';
import { get, put } from '@src/utils/localStorage';

const host = process.env.GATSBY_AZURE_API_GATEWAY_BASE_URL;

let defaultHeaders = {};
if (process.env.GATSBY_AZURE_API_GATEWAY_TRACE === 'true') {
	//Subscription key required for tracing
	defaultHeaders['Ocp-Apim-Trace'] = 'true';
	defaultHeaders['Ocp-Apim-Subscription-Key'] = process.env.GATSBY_AZURE_API_GATEWAY_KEY;
}

const config = {
	baseURL: `${host}`,
	defaultHeaders: defaultHeaders,
};

const client = new Client(config);

const languagesLookup = {
	da: 'da-DK',
	en: 'en-US',
	sv: 'sv-SE',
};

export function isInvalidSession(error) {
	if (error.response?.status === 401) {
		return true;
	}
	const errorMessages = error.response?.data?.messages || error.response?.data?.message;
	if (errorMessages) {
		if (Array.isArray(errorMessages)) {
			return errorMessages.some(x => x.includes('Error decoding token'));
		} else {
			return errorMessages == 'Error decoding token';
		}
	}
	return false;
}

const setConfigAndHeaders = (config, language = 'en-US', captchaToken = null) => {
	const { useToken = true, headers = {}, ...rest } = config;
	if (useToken) {
		const token = loadToken();
		if (token) {
			headers['Telematics-AuthToken'] = 'JWT ' + token;
		}
	}
	if (languagesLookup[language]) {
		headers['Accept-Language'] = languagesLookup[language];
	}
	if (captchaToken) {
		headers['g-recaptcha-response'] = captchaToken;
	}
	return { headers, ...rest };
};

export async function getOnfidoSdkToken(market) {
	try {
		return await client.get({
			endpoint: `onfido/get_sdk_token/?market=${market}`,
			params: undefined,
			...setConfigAndHeaders(config),
		});
	} catch (error) {
		throw error;
	}
}

export async function verifyNemIdApiCall(nemIdData) {
	const resp = await client.post({
		endpoint: 'customer/check',
		data: nemIdData,
		...setConfigAndHeaders(config),
	});
	return resp;
}
export async function saveCustomerCaseApiCall(customerCaseData, market) {
	const resp = await client.post({
		endpoint: `customer/${market}/billing_address/`,
		data: customerCaseData,
		...setConfigAndHeaders(config),
	});
	return resp;
}

/* 
	This function is used to initiate identity check transaction.
	It is used for both BankID and MitID.
	@param ssn - Social Security Number
	@param country - Country code
	@param language - Language code
	@return - Identity check transaction response
*/
export async function doInitiateIdentityCheckTransaction(ssn, country, language) {
	try {
		const resp = await client.post({
			endpoint: `${country}/customer/identity/transaction/`,
			data: {
				personal_number: ssn,
				redirectUrl: window.location.href,
			},
			...setConfigAndHeaders(config, language),
		});
		return resp;
	} catch (error) {
		throw error;
	}
}

/* This function is used to initiate BankID transaction. Uses doInitiateIdentityCheckTransaction as generic API for Scrive transactions.
	@param ssn - Social Security Number
	@param country - Country code
	@param language - Language code
	@return - BankID transaction response
*/
export async function doInitiateBankIDTransaction(ssn, country, language) {
	return doInitiateIdentityCheckTransaction(ssn, country, language);
}
/* This function is used to initiate MitID transaction. Uses doInitiateIdentityCheckTransaction as generic API for Scrive transactions.
	@param ssn - Social Security Number
	@param country - Country code
	@param language - Language code
	@return - MitID transaction response
*/
export async function doInitiateMitIDTransaction(ssn, country, language) {
	return doInitiateIdentityCheckTransaction(ssn, country, language);
}
/* This function is used to get identity check transaction.
	@param transaction_id - Identity check transaction id
	@param country - Country code
	@return - Identity check transaction response
*/
export async function getIdentityCheckTransaction(transaction_id, country) {
	return await client.get({
		endpoint: `${country}/customer/identity/transaction/${transaction_id}`,
		...setConfigAndHeaders({ params: { registration: true } }),
	});
}

/* This function is used to get BankID transaction. Uses getIdentityCheckTransaction as generic API for Scrive transactions
	@param transaction_id - BankID transaction id
	@param country - Country code
	@return - BankID transaction response
*/
export async function getBankIDTransaction(transaction_id, country) {
	return getIdentityCheckTransaction(transaction_id, country);
}

export async function createCustomerApiCall(customerInformation, language, market, captchaToken = null) {
	const resp = await client.post({
		endpoint: `/${market}/customers/`,
		data: customerInformation,
		...setConfigAndHeaders(config, language, captchaToken),
	});
	return resp;
}

export async function onfidoVerifyApiCall(onfidoData, market) {
	const resp = await client.post({
		endpoint: `onfido/check/?market=${market}`,
		data: onfidoData,
		...setConfigAndHeaders(config),
	});
	return resp;
}

export async function resetOnfidoStatus(market) {
	await client.post({
		endpoint: `onfido/reset_status/?market=${market}`,
		...setConfigAndHeaders(config),
	});
}

export async function getOnboardingStatusApiCall() {
	return await client.get({
		endpoint: 'customer/status',
		params: undefined,
		...setConfigAndHeaders(config),
	});
}

export async function getCreditCardTokenApiCall(country = 'DK') {
	return await client.post({
		endpoint: 'card/initialization_token/',
		data: { country: country },
		...setConfigAndHeaders(config),
	});
}

export async function createCustomerCardApiCall(customerId, payment_card_token, lat, lng) {
	return await client.post({
		endpoint: `customers/${customerId}/credit_card/`,
		data: {
			payment_card_token: payment_card_token,
			in_use: true,
			card_tag: 'personal',
			lat: lat,
			lng: lng,
		},
		...setConfigAndHeaders(config),
	});
}

export async function getMobileVerificationCodeApiCall(customerId, phonenumber) {
	return await client.post({
		endpoint: `customer/${customerId}/send_verification_code`,
		data: { phone_number: phonenumber },
		...setConfigAndHeaders({ params: { registration: true } }),
	});
}

export async function getMobileVerificationCodeNewPhonenumberApiCall(customerId, phonenumber) {
	return await client.post({
		endpoint: `customer/${customerId}/update_phone_and_send_verification_code`,
		data: { phone_number: phonenumber },
		...setConfigAndHeaders({ params: { registration: true } }),
	});
}

export async function verifyPhoneNumberApiCall(customerId, verificationCode) {
	return await client.post({
		endpoint: `customer/${customerId}/verify_phone_number`,
		data: { verification_code: verificationCode },
		...setConfigAndHeaders({ params: { registration: true } }),
	});
}

export async function checkBankIdApiCall(customerId, transaction_id) {
	return await client.post({
		endpoint: 'verify_bankid/client_check_bankid',
		data: { transaction_id },
		...setConfigAndHeaders({ params: { registration: true } }),
	});
}

export async function resetPasswordApiCall(phone_number, language) {
	return await client.post({
		endpoint: 'customer/reset_password',
		data: { phone_number: phone_number },
		...setConfigAndHeaders({ useToken: false }, language),
	});
}

export async function fetchCustomerInfoApiCall(market) {
	const response = await client.get({
		endpoint: 'customer',
		...setConfigAndHeaders({ useToken: true, params: { registration: true, market: market } }),
	});

	const {
		id,
		email,
		first_name,
		last_name,
		phone_number,
		mailing_address_display,
		can_reserve_car,
		phone_number_verified,
		has_valid_payment_card,
		driving_license,
		business_entity,
		can_reserve_car_for_business,
		can_schedule_car,
		can_schedule_car_for_business,
		country,
		is_under_20_years_age,
		billing_address,
		identity_verification_status,
		b2bDomainOnboarding,
	} = response.data;
	const address = mailing_address_display ? mailing_address_display.replace(',', '&').split('&') : [];
	let onboardingStatus = {};

	const ssn = driving_license?.license_number ? driving_license?.license_number : '';
	if (
		!can_reserve_car ||
		!can_schedule_car ||
		!phone_number_verified ||
		!has_valid_payment_card ||
		!driving_license.is_license_verified
	) {
		onboardingStatus = await getOnboardingStatusApiCall();
	}

	return {
		customerId: id,
		firstName: first_name,
		lastName: last_name,
		ssn: ssn,
		email: email,
		phonenumber: phone_number,
		address,
		canReserve: can_reserve_car,
		phonenumberVerified: phone_number_verified,
		validPaymentCard: has_valid_payment_card,
		drivingLicense: driving_license,
		businessEntity: business_entity,
		b2bDomainOnboarding: b2bDomainOnboarding,
		canReserveBusiness: can_reserve_car_for_business,
		canSchedule: can_schedule_car,
		canScheduleBusiness: can_schedule_car_for_business,
		country: country,
		onboardingStatus: onboardingStatus.data,
		isAge20DkBlocked: is_under_20_years_age && market === 'dk',
		billingAddress: billing_address,
		identityVerificationStatus: identity_verification_status,
	};
}

export async function azureAuthApiCall(username, password, rememberToken) {
	const resp = await client.post({
		endpoint: 'customer/authentication',
		data: {
			username,
			password,
		},
		...setConfigAndHeaders({ useToken: false, params: { registration: true } }),
	});
	const { auth_token } = resp.data;
	setTokenValue(auth_token);
	setTokenValueInStorage(auth_token, rememberToken);
	return resp;
}

export async function getActiveMembershipApiCall(customerId) {
	return await client.get({
		endpoint: `customer/${customerId}/active_membership`,
		params: undefined,
		...setConfigAndHeaders({}),
	});
}

export async function getStationsApiCall(marketIds, isCustomerLoggedIn, tag, excludeTag) {
	const allStationsList = [];
	await Promise.all(
		marketIds.map(async marketId => {
			const stationsList = await getStationsForMarket(marketId, isCustomerLoggedIn, tag, excludeTag);
			for (const station of stationsList) {
				if (!allStationsList.some(x => x.id === station.id)) {
					allStationsList.push(station);
				}
			}
		})
	);
	return allStationsList;
}

async function getStationsForMarket(marketId, isCustomerLoggedIn, tag, excludeTag) {
	const endpoint = isCustomerLoggedIn ? 'station' : 'visitor_station';
	const params = {};
	if (marketId) {
		params['market_id'] = marketId;
	}
	if (tag) {
		params['tag'] = tag;
	}
	if (excludeTag) {
		params['exclude_tag'] = excludeTag;
	}
	const stationResponse = await client.get({
		endpoint: endpoint,
		params: params,
		...setConfigAndHeaders({}),
	});

	return stationResponse.data && stationResponse.data.stations ? stationResponse.data.stations : [];
}

export async function getStationsCachedApiCall(marketIds, isCustomerLoggedIn, tag, excludeTag) {
	const defaultStationsCache = {
		stations: undefined,
		timestamp: new Date(),
	};
	const stationsCacheKey = `stations-${marketIds.toString()}${isCustomerLoggedIn}${tag}${excludeTag}`;
	const stationsCacheJson = get(stationsCacheKey);
	let stationsCache = defaultStationsCache;
	if (stationsCacheJson) {
		stationsCache = JSON.parse(stationsCacheJson);
		stationsCache.timestamp = new Date(stationsCache.timestamp);
	}

	const now = new Date();
	const secondsCacheDiff = (now.getTime() - stationsCache.timestamp.getTime()) / 1000;
	if (stationsCache.stations && stationsCache.stations.length > 0 && secondsCacheDiff < 600) {
		const cachedStations = stationsCache.stations;
		return cachedStations;
	} else {
		const stations = await getStationsApiCall(marketIds, isCustomerLoggedIn, tag, excludeTag);
		const stationsCacheData = JSON.stringify({
			stations: stations,
			timestamp: new Date(),
		});
		put(stationsCacheKey, stationsCacheData);
		return stations;
	}
}

export async function getStationAvailableVehicleTypesApiCall(
	isCustomerLoggedIn,
	stationId,
	startTime,
	endTime,
	bookingType,
	language
) {
	if (isCustomerLoggedIn) {
		return await client.get({
			endpoint: `booking/${stationId}/station_available_vehicle_types?start_datetime=${startTime}&end_datetime=${endTime}`, // start_datetime and end_datetime are added here to avoid URL encoding in axios, APIGateway can't handle it
			params: {
				rental_mode: bookingType,
			},
			...setConfigAndHeaders({ useToken: true }, language),
		});
	} else {
		return await client.get({
			endpoint: `booking/${stationId}/station_visitor_available_vehicle_types?start_datetime=${startTime}&end_datetime=${endTime}`, // start_datetime and end_datetime are added here to avoid URL encoding in axios, APIGateway can't handle it
			params: { rental_mode: bookingType },
			...setConfigAndHeaders({ useToken: false }, language),
		});
	}
}

export async function getScheduledRentalApiCall(language) {
	return await client.get({
		endpoint: 'booking/scheduled_rental',
		params: undefined,
		...setConfigAndHeaders({ useToken: true }, language),
	});
}

export async function getScheduledRentalCancelFeeApiCall(bookingId, language) {
	return await client.get({
		endpoint: `booking/scheduled_rental/${bookingId}/cancel_fee`,
		params: undefined,
		...setConfigAndHeaders({ useToken: true }, language),
	});
}

export async function createScheduledRentalApiCall(serviceId, bookingInformation, language) {
	return await client.post({
		endpoint: `booking/${serviceId}/scheduled_rental`,
		data: bookingInformation,
		...setConfigAndHeaders({ useToken: true }, language),
	});
}

export async function cancelScheduledRentalApiCall(bookingId, language) {
	return await client.patch({
		endpoint: `booking/scheduled_rental/${bookingId}/cancel`,
		...setConfigAndHeaders({ setToken: true }, language),
	});
}

export async function getAvailableAddonsApiCall(serviceId, selectedCarId, startTime, endTime, language) {
	return await client.get({
		endpoint: `booking/available_addons?service_id=${serviceId}&vehicle_type_id=${selectedCarId}&start_datetime=${startTime}&end_datetime=${endTime}`,
		params: undefined,
		...setConfigAndHeaders({ useToken: true }, language),
	});
}

export async function getCustomerCreditCardApiCall(customerId, language) {
	return await client.get({
		endpoint: `customers/${customerId}/card/`,
		params: undefined,
		...setConfigAndHeaders({ useToken: true }, language),
	});
}

export async function getStationStartDatesApiCall(stationId, rentalMode) {
	return await client.get({
		endpoint: `booking/${stationId}/station_start_dates`,
		params: { rental_mode: rentalMode },
		...setConfigAndHeaders({ useToken: true }),
	});
}

export async function getStationStartTimesApiCall(stationId, rentalMode, startDate) {
	return await client.get({
		endpoint: `booking/${stationId}/station_start_times`,
		params: { start_date: startDate, rental_mode: rentalMode },
		...setConfigAndHeaders({ useToken: true }),
	});
}

export async function getStationEndDatesApiCall(stationId, rentalMode, startDate) {
	return await client.get({
		endpoint: `booking/${stationId}/station_end_dates`,
		params: { start_datetime: startDate, rental_mode: rentalMode },
		...setConfigAndHeaders({ useToken: true }),
	});
}

export async function getStationEndTimesApiCall(stationId, rentalMode, startDate, endDate) {
	return await client.get({
		endpoint: `booking/${stationId}/station_end_times`,
		params: {
			start_datetime: startDate,
			end_date: endDate,
			rental_mode: rentalMode,
		},
		...setConfigAndHeaders({ useToken: true }),
	});
}

export async function createCrmLeadApiCall(leadData) {
	return await client.post({
		endpoint: 'crm/lead',
		data: leadData,
		...setConfigAndHeaders({ useToken: false }),
	});
}

export async function checkDriverLicenseApiCall(customerId, driverLicenseExpDate) {
	return await client.post({
		endpoint: `customer/${customerId}/driver_license_check`,
		data: {
			expiry_date: driverLicenseExpDate,
		},
		...setConfigAndHeaders({ useToken: true }),
	});
}

export async function customerCheckApiCall(transactionId, country) {
	return await client.post({
		endpoint: 'customer/check',
		data: {
			transaction_id: transactionId,
			country: country,
		},
		...setConfigAndHeaders({ useToken: true }),
	});
}

export async function errorLogExceptionToSF(error, source, stage, userId) {
	let webSource = source;
	if (typeof window !== 'undefined') {
		webSource = `${window.location.href} - ${source}`;
	}
	if (error?.response?.data?.messages && error?.response?.data?.messages?.length > 0) {
		const errorMessage = error.response.data.messages.join(', ');
		errorLogToSF(error.response.status, errorMessage, webSource, stage, userId);
	} else {
		errorLogToSF(error.status, error.message, webSource, stage, userId);
	}
}

export async function errorLogToSF(status, message, source, stage, userId) {
	const data = {
		status: status,
		message: message,
		source: source,
		stage: stage,
	};
	if (userId !== undefined && !isNaN(userId)) {
		data['user_id'] = userId;
	}
	return await client.post({
		endpoint: 'log',
		data: data,
		...setConfigAndHeaders({ useToken: false }),
	});
}

export async function getVehicleLeasingEntities() {
	return await client.get({
		endpoint: 'vehicle_leasing_entities',
		...setConfigAndHeaders({ useToken: true }),
	});
}

export async function getVehicleLeasingEntitiesForStation(stationId) {
	return await client.get({
		endpoint: `vehicle_leasing_entities/${stationId}`,
		...setConfigAndHeaders({ useToken: true }),
	});
}

export async function saveCustomerMarketingConsentsApiCall(consentReminder, consentMarketing) {
	const data = {
		consentReminder: consentReminder,
		consentMarketing: consentMarketing,
	};
	return await client.post({
		endpoint: 'customer/save-marketing-consents',
		data: data,
		...setConfigAndHeaders({ useToken: true }),
	});
}

export async function getMarketingConsentsApiCall() {
	return await client.get({
		endpoint: 'get_marketing_consents/',
		...setConfigAndHeaders({ useToken: true }),
	});
}

export async function submitPayslipsApiCall(fileNames) {
	const data = {
		fileNames: fileNames,
	};
	return await client.post({
		endpoint: 'customer/submit-payslips',
		data: data,
		...setConfigAndHeaders({ useToken: true }),
	});
}

export async function samlRequest(SAMLRequest, RelayState, customerId) {
	return await client.post({
		endpoint: `saml/${customerId}`,
		data: {
			saml_request: SAMLRequest,
			relay_state: RelayState,
		},
		...setConfigAndHeaders({ useToken: true }),
	});
}
