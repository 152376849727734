import { useStaticQuery, graphql } from 'gatsby';
import { useConnect } from 'redux-bundler-hook';
import { decode } from 'he';

const useTranslations = () => {
	const data = useStaticQuery(graphql`
		query {
			allTranslation {
				edges {
					node {
						key
						value
					}
				}
			}
		}
	`);

	const { language } = useConnect('selectLanguage');

	function translate(key, replacements) {
		// Return the translation or the requested key because
		// it is easier to see where we are missing translations.
		let languageKey = language + '-' + key;
		const translation = data.allTranslation.edges.find(({ node }) => {
			return node.key === languageKey;
		});

		if (translation) {
			//Workaround for Pimcore 11
			translation.node.value = decode(translation.node.value);
		}

		return translation
			? replacements
				? replaceTranslations(translation, replacements)
				: translation.node.value
			: replacements
			? `${languageKey}, ${replacements.join(', ')}`
			: key;
	}

	return translate;
};

export default useTranslations;

function replaceTranslations(translation, replacements) {
	const numberOfReplacements = (translation.node.value.match(/%/g) || []).length;
	const splits = translation.node.value.split('%');
	const slicedReplacements = replacements.slice(0, numberOfReplacements);
	return splits.map((v, i) => [v, slicedReplacements[i]]);
}

// TODO add fallback fetch for empty key directly to pimcore?
