import React from 'react'
import useTranslations from '@src/hooks/useTranslations'
import { TextField } from '@mui/material'

export default function Email({ values, touched, errors, handleChange, handleBlur }) {
	const t = useTranslations()
	return (
		<TextField
			label={t('kinto.form.email')}
			id={t('kinto.form.email')}
			variant='outlined'
			name='email'
			type='email'
			required
			value={values.email}
			onChange={handleChange}
			onBlur={handleBlur}
			error={errors.email && touched.email}
			helperText={errors.email && touched.email && errors.email}
		/>
	)
}
