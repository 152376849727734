const DAY_IN_MS = 24 * 60 * 60 * 1000

export const setCookie = (cname, cvalue, expireInDays) => {
	if (typeof window !== 'undefined' && window.document) {
		let expires = null
		if (expireInDays) {
			const d = new Date()
			d.setTime(d.getTime() + expireInDays * DAY_IN_MS)
			expires = 'expires=' + d.toUTCString()
		}
		document.cookie = `${cname}=${cvalue};${expires ? expires + ';' : ''}path=/`
	}
}

export const getCookie = (cname) => {
	if (typeof window !== 'undefined' && window.document) {
		const cookieName = `${cname}=`
		const decodedCookie = decodeURIComponent(document.cookie)
		const ca = decodedCookie.split(';')
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i]
			while (c.charAt(0) === ' ') {
				c = c.substring(1)
			}
			if (c.indexOf(cookieName) === 0) {
				return c.substring(cookieName.length, c.length)
			}
		}
	}
	return ''
}

export const checkCookie = (cname) => {
	const cookie = getCookie(cname)
	return !!cookie
}
