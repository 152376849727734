export const loadState = (stateKey) => {
	try {
		const serializedState = localStorage.getItem(stateKey)
		if (serializedState === null) {
			return undefined
		}
		return JSON.parse(serializedState)
	} catch (error) {
		return undefined
	}
}

export const saveState = (stateKey, state) => {
	try {
		const serializedState = JSON.stringify(state)
		localStorage.setItem(stateKey, serializedState)
	} catch (error) {
		console.error(error)
	}
}

export const put = (key, value) => {
	if (typeof window !== 'undefined' && window.localStorage) {
		return localStorage.setItem(key, value)
	}
}

export const get = (key) => {
	if (typeof window !== 'undefined' && window.localStorage) {
		return localStorage.getItem(key)
	}
}

export const del = (key) => {
	if (typeof window !== 'undefined' && window.localStorage) {
		return localStorage.removeItem(key)
	}
}

export const TOKEN_KEY = 'kinto_token'
