const registration = {
	name: "registration",
	getReducer: () => {
		const initialState = {
			selectedPriceLevel: null,
			userRegistrationInfo: null,
			returnToBookingAfterRegistration: null,
		};
		return (state = initialState, { type, payload }) => {
			switch (type) {
				case "SET_CURRENT_PRICE_LEVEL":
					return { ...state, selectedPriceLevel: payload };
				case "SET_USER_REGISTRATION_INFO":
					return { ...state, userRegistrationInfo: payload };
				case "SET_RETURN_TO_BOOKING_AFTER_REGISTRATION":
					return {
						...state,
						returnToBookingAfterRegistration: payload,
					};
				default:
					return state;
			}
		};
	},
	doSetChoosenAccountLevel:
		(priceLevel) =>
		({ dispatch }) => {
			dispatch({ type: "SET_CURRENT_PRICE_LEVEL", payload: priceLevel });
		},
	doSetUserRegistrationInformation:
		(userInfo) =>
		({ dispatch }) => {
			dispatch({ type: "SET_USER_REGISTRATION_INFO", payload: userInfo });
		},
	doSetReturnToBookingAfterRegistration:
		(value) =>
		({ dispatch }) => {
			dispatch({
				type: "SET_RETURN_TO_BOOKING_AFTER_REGISTRATION",
				payload: value,
			});
		},
	selectSelectedPriceLevel: (state) => state.registration.selectedPriceLevel,
	selectUserRegistrationInfo: (state) =>
		state.registration.userRegistrationInfo,
	selectReturnToBookingAfterRegistration: (state) =>
		state.registration.returnToBookingAfterRegistration,
};
export default registration;
