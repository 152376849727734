import { useEffect, useState } from 'react'

const useBrowserIE = () => {
	const [isIE, setIsIE] = useState(null)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		if (window.navigator.userAgent.match(/MSIE |Trident\//)) {
			setIsIE(true)
		}
	})

	return isIE
}
export default useBrowserIE
