export const CHECKOUT_NAME_TAG = {
	private: 'online-subscription-private',
	bussiness: 'online-subscription-professional',
}

export const BOOKING_STEPS_TAG = {
	selectStation: 'select-station',
	selectModel: 'select-model',
	selectTime: 'select-time',
	bookingSummary: 'booking-summary',
	transactionCompleted: 'transaction-completed',
}

export const REGISTER_NAME_TAG = {
	private: 'register-flow-private',
	bussiness: 'register-flow-professional',
}

export const REGISTER_STEPS_TAG = {
	contactData: 'contact-data',
	checkPhone: 'check-phone',
	identityCheckIntro: 'identity-check-intro',
	driverLicenseCheck: 'driver-license-check',
	idCheck: 'id-check',
	paymentCardData: 'payment-card-data',
	onboardingComplete: 'onboarding-complete',
}

export const CONTACT_FORMS_TAG = {
	newDevelopment: 'contact-new-developments',
	b2b: 'contact-b2b',
}
