import LeasingEntity from './LeasingEntity.model';
import * as _ from 'lodash';
import { PimcoreImage, PimcoreMainImage } from './PimcoreImage.model';

export type FeatureSet = {
	FeatureName: string;
	FeatureValue: string;
};
export default class VehicleType {
	id: string;
	name: string;
	RidecellID: string;
	leasingEntities: Array<LeasingEntity> = [];
	FlexPrice: string; //Pimcore FlexPrice field
	Model: string;
	Images: PimcoreImage[];
	Size: string;
	Towbar: string;
	MainImage: PimcoreMainImage;
	Brand: string;
	vehicle_image_url: string;
	svFlexBookingDocumentPath: string;
	enFlexBookingDocumentPath: string;
	AddtionalInformation: string;

	//Vehicle features set
	dkFeatureSet: FeatureSet;
	enFeatureSet: FeatureSet;
	svFeatureSet: FeatureSet;

	constructor(vehicleType: Partial<VehicleType>) {
		Object.assign(this, vehicleType);
	}

	GetAvailableCommitmentDurations() {
		return _.uniq(this.leasingEntities.flatMap(le => le.commitment_duration)).sort((a, b) => a - b);
	}
	GetAvailableStations() {
		return this.leasingEntities.flatMap(le => le.stations);
	}

	GetAvailableCities() {
		return _.uniq(this.leasingEntities.flatMap(le => le.cities).map(c => c.name));
	}

	GetAvailablePrices() {
		return _.uniq(this.leasingEntities.flatMap(item => item.plan.fee));
	}
	GetDefaultPrice() {
		return this.FlexPrice;
	}
}
