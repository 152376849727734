import BEMHelper from 'react-bem-helper';
import React, { useEffect } from 'react';
import { useConnect } from 'redux-bundler-hook';
import Link from '@src/components/Link';
import { SITE_TYPES } from '@src/bundles/site';
import { isAbsoluteURL } from '@src/utils/navigate';

const bem = new BEMHelper('header');

export const ProductsMenu = ({ location, items, language }) => {
	const {
		service,
		doSetService,
		doResetBookingSelectedCar,
		doResetBookingInfo,
		doSetFlexSelectedOption,
		doSetSiteType,
		doSetBookingSiteType,
		siteType,
	} = useConnect(
		'selectService',
		'doSetService',
		'doResetBookingSelectedCar',
		'doResetBookingInfo',
		'doSetFlexSelectedOption',
		'doSetSiteType',
		'doSetBookingSiteType',
		'selectSiteType'
	);

	useEffect(() => {
		if (location.href.includes(`kinto-${SITE_TYPES.SHARE}`)) {
			// Set service only when needed, setService resets selected station and vehicle
			if (service !== SITE_TYPES.SHARE) {
				doSetService(SITE_TYPES.SHARE);
			}
		} else if (location.href.includes(`kinto-${SITE_TYPES.FLEX}`)) {
			// Set service only when needed, setService resets selected station and vehicle
			if (service !== SITE_TYPES.FLEX) {
				doSetService(SITE_TYPES.FLEX);
			}
		}
	}, [location]);

	if (siteType === SITE_TYPES.BUSINESS) {
		items = items.map(item => {
			const url = `${item.url}/foretag`;
			return { ...item, url };
		});
	}

	const setServiceHandler = url => {
		const PRIVATE_SHARE_URL = `kinto-${SITE_TYPES.SHARE}`;
		const BUSINESS_SHARE_URL = `kinto-${SITE_TYPES.SHARE}/foretag`;
		const PRIVATE_FLEX_URL = `kinto-${SITE_TYPES.FLEX}`;
		const BUSINESS_FLEX_URL = `kinto-${SITE_TYPES.FLEX}/foretag`;

		if (url.endsWith(PRIVATE_SHARE_URL) || url.endsWith(BUSINESS_SHARE_URL)) {
			doSetService(SITE_TYPES.SHARE);
		} else if (url.endsWith(PRIVATE_FLEX_URL) || url.endsWith(BUSINESS_FLEX_URL)) {
			doSetService(SITE_TYPES.FLEX);
		}
	};

	const ProductLinks = ({ items }) => {
		return items
			.filter(item => item.language === language)
			.map(({ url, label, id }) => (
				<li {...bem('menu-item')} key={id}>
					<Link
						data-testid={'products-menu-link-item'}
						to={url}
						onClick={() => {
							if (isAbsoluteURL(url)) {
								return;
							}
							doResetBookingSelectedCar();
							doResetBookingInfo();
							doSetFlexSelectedOption(null);
							doSetSiteType(siteType);
							doSetBookingSiteType(siteType);
							setServiceHandler(url);
						}}
						{...bem('link', 'type-desktop', 'active service')}
					>
						{label}
					</Link>
				</li>
			));
	};

	return (
		<ul {...bem('main-menu-products')}>
			<ProductLinks items={items} />
		</ul>
	);
};
